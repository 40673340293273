<script>
export default {
  methods:{
    andongzaixian(){
      window.open("https://cnmall.antonoil.com/")
    },
    antong(){
      window.open("https://cn.atoilgas.com/ ")
    },
    youtian(){
      window.open("https://www.aomserv.com/en ")
    },
    tongao(){
      window.open("https://www.t-all.cn/")
    },
    anyi(){
      window.open("https://cn.antoneasy.com/")
    },
    gptsMove1(){
      window.open("https://cn.atoilgas.com/")
    },
    gptsMove2(){
      window.open("https://cn.oilgasdao.com/")
    },
    gptsMove3(){
      window.open("https://www.oilgascommunity.com/")
    },
    gptsMove4(){
      window.open("https://cn.oilgasgpts.com/")
    },
    gptsMove5(){
      window.open("https://cn.oilgasinfoai.com/")
    },
    gptsMove6(){
      window.open("https://www.oilgasjobai.com/")
    },
    gptsGround1(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",2);
      } else {
        sessionStorage.setItem("ground", 1)
        window.open("/", "_blank");
      }

    },
    gptsGround2(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",7);
      } else {
        sessionStorage.setItem("ground", 2)
        window.open("/", "_blank");
      }

    },
    gptsGround3(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",6);
      } else {
        sessionStorage.setItem("ground", 3)
        window.open("/", "_blank");
      }

    },
    gptsGround4(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",7);
      } else {
        sessionStorage.setItem("ground", 4)
        window.open("/", "_blank");
      }
    },
    gptsGround5(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",6);
      } else {
        sessionStorage.setItem("ground", 6)
        window.open("/", "_blank");
      }
    },
    showComment(){
      window.open("/Feedback");
    },
    showProduct(){
      window.open("/productFeedback");
    },
    showNet(){
      window.open("/netFeedback");
    }
  }
}
</script>

<template>
<div class="BottomContainer" style="padding: 6vh 6vw">
  <div class="logo">
    <img src="../../assets/LOGO 2.png" style="width: 25vw;height: 8vw">
  </div>
  <div>
    <div @click="gptsProfessional()" class="title1">
      生态平台
    </div>
    <!-- <div @click="gptsMove2()" class="title2">
      油气岛
    </div>
    <div @click="gptsMove3()" class="title2">
      油气社区
    </div>
    <div @click="gptsMove4()" class="title2">
      油气通
    </div>
    <div @click="gptsMove5()" class="title2">
      油气知识
    </div>
    <div @click="gptsMove6()" class="title2">
      油气人才
    </div> -->
    <div @click="andongzaixian" class="title2">
     安东在线服务
    </div>
    <div @click="gptsMove4()" class="title2">
      油气通
    </div>
    <div @click="antong" class="title2">
     安同油气商城
    </div>
    <div @click="youtian" class="title2">
      油田管理
    </div>
    <div @click="tongao" class="title2">
      通奥检测
    </div>
    <div @click="anyi" class="title2">
      安易能租
    </div>
  </div>
  <!-- <div>
    <div @click="gptsProfessional()" class="title1">
      地质工程云平台
    </div>
    <div @click="gptsGround3()" class="title2">
      全球在线专家支持服务
    </div>
    <div @click="gptsGround1()" class="title2">
      工程地质GPTS
    </div>
    <div @click="gptsGround2()" class="title2">
      油气田开发系列技术服务
    </div>
  </div> -->
  <div>
    <div class="title1">
      服务反馈
    </div>
    <div @click="showComment" class="title2">
      作业项目服务评价反馇
    </div>
    <div @click="showProduct" class="title2">
      产品评价反馈
    </div>
    <div @click="showNet" class="title2">
      网页使用体验反馈
    </div>
  </div>
  <div>
    <div class="title1">
      关注我们
    </div>
    <div >
      <div style="display:flex;">
        <img src="../../assets/antonlogo.png" style="width: 26vw;height: 3.6vw;padding: 3vw 4vw;border: white 1px solid;border-radius: 20px">
        <img src="../../assets/Group 16.png" style="width: 16vw;height: 7vw;padding: 1vw 8vw;border: white 1px solid;border-radius: 20px;margin-left: 5vw">
      </div>
      <div>

      </div>
    </div>
    <div >

    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.BottomContainer{
  .title1{
    color: white;
    font-size: 4.5vw;
    margin: 4vh 0 2vh 0;
  }
  .title2{
    color: whitesmoke;
    font-size: 4vw;
    margin: 0.7vw 0;
  }
}
</style>
